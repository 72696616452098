<template>
  <div class="win_bid">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-select v-model="tendersId" placeholder="请选择标号" @change="onSearch" clearable>
          <el-option v-for="(item, index) in options" :key="index" :label="item.tendersLabel" :value="item.tendersId"></el-option>
        </el-select>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="number" label="序号" width="100">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="supplierName" label="供应商名称" min-width="150"></el-table-column>
          <el-table-column show-overflow-tooltip prop="contacts" label="联系人" min-width="120"></el-table-column>
          <el-table-column show-overflow-tooltip prop="telphone" label="联系电话" min-width="120"></el-table-column>
          <el-table-column show-overflow-tooltip prop="totalCount" label="投标商品总数" min-width="120"></el-table-column>
          <el-table-column show-overflow-tooltip prop="count" label="中标商品总数" min-width="120"></el-table-column>
          <el-table-column show-overflow-tooltip prop="" label="中标率" min-width="100">
            <template slot-scope="scope">{{ scope.row.biddingRate }}%</template>
          </el-table-column>
          <el-table-column label="操作" min-width="60" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" plain @click="showDetail(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      tendersId: "",
      tendersLabel: "",
      tableData: [],
      options: [],

      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getList()
    this.getTendersLabelList()
  },
  methods: {
    getTendersLabelList() {
      this.$axios.get(this.$api.tendersInfoList).then(res => {
        this.options = res.data.result
      })
    },
    getList() {
      this.loading = true
      this.$axios
        .get(this.$api.supplierBiddingProductPageList, {
          params: {
            tendersId: this.tendersId,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list
            this.totalItemsCount = res.data.result.totalCount
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    showDetail(row) {
      this.$router.push({ path: "/supplier/winBidProduct", query: { name: row.supplierName, id: this.tendersLabel } })
    },
    onSearch(e) {
      this.tendersLabel = e ? this.options.find(item => item.tendersId == e).tendersLabel : ""
      this.tableData = []
      this.currentPage = 1
      this.getList()
    },
    // 图片预览
    previewImage(url) {
      this.imgList = url
      this.showViewer = true
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getList()
    },
  },
}
</script>

<style scoped lang="scss">
.win_bid {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    .el-select {
      margin-bottom: 20px;
      margin-left: 20px;
    }

    .el-select {
      width: 250px;
    }
  }
}
</style>
